import React, { PureComponent, useMemo } from "react";
import { Calendar, momentLocalizer, Day } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { connect } from "react-redux";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../App.css';
import Icon from "../UiComponents/Icon";
import Menu from "../App/_layouts/default/Menu/Menu";
import { MobileView, BrowserView, MobileOnlyView, TabletView } from "react-device-detect";
import {
  Container,
  customStyles,
  UserOptionsContainer,
  HeaderContainer,
  Logo,
  StickyHeader,
  StickyBodyMobile,
  LogoWithUserContainer,
  ButtonContainer,
  Row,
  SelectContainer,
  Column,
  IconSwitch,
  Burger,
  ChapterTitle,
  ContainerVehicle,
} from "./calendar.styles";
import {
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  createEvent,
  getStudentData,
  updateEventStatus,
  updateEvent,
} from "../../actions/schedulerActions";
import SivanPlusLogo from "../App/_layouts/default/Header/sivanLogo.png";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import { getStudents } from "../../actions/studentesActions";
import StudentBasicInfo from "./StudentBasicInfo/StudentBasicInfo";
import { getFilters, getUsers, deleteFilter, addFilter } from "../../actions/userActions";
import { getLicenseTypes } from "../../actions/licenseTypes";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/he";
import Button from "../UiComponents/Buttons/Default";
import SplitButton from "../UiComponents/Buttons/SplitButton"
import { getVehicles } from "../../actions/vehicleActions";
import { Collapse } from "react-collapse";
import EventVehicleDayView from "./CalendarViews/EventVehicleDayView";
import EventDayView from "./CalendarViews/EventDayView";
import { uniq } from "lodash";
import Toggle from "react-toggle";
import Select from "react-select";
import NewLesson from "./NewLesson/NewLesson";
import NoteChange from "./NoteChange/NoteChange";
import ErrorModal from "../Modal/ErrorModal";
import EditEvent from "./EditEvent/EditEvent";
import EventStatusChange from "./EventStatusChange/EventStatusChange";
import Promt from "../App/Promt/Promt";
import DefaultDatePicker from "../UiComponents/DatePicker/Default";
import DefaultButton from "../UiComponents/Buttons/Default";
import TagList from "../UiComponents/Tag/List";

const DnDCalendar = withDragAndDrop(Calendar)
const format = 'hh:mm';
const localizer = momentLocalizer(moment);
// const _oldFuntion = localizer.formats.dayHeaderFormat
// localizer.formats.agendaHeaderFormat = (_ref2, culture, local) => {
//   const result = _oldFuntion(_ref2, culture, local);
//   return result.split(' ', 2)[0];
// }
const messages = {
  allDay: "כל היום",
  previous: "הקודם",
  next: "הבא",
  today: "היום",
  month: "חודש",
  week: "שבוע",
  work_week: "שבוע עבודה",
  day: "יום",
  agenda: "אגנדה",
  date: "תאריך",
  time: "שעה",
  event: "אירוע",
  myweek: "יומי אופנועים",
};

class Calendara extends PureComponent {
  constructor(props) {
    super(props);
    this.studentProfile = null
    this.bigCalendar = null
    this.format = null

    this.state = {
      dateText: "",
      isFoldMenu: isMobile ? true : false,
      teacherOptions: [],
      blockText: "",
      isBlockActive: false,
      isNewLessonModalOpen: false,
      isUpdateLessonModalOpen: false,
      isEventStatusChangeModalOpen: false,
      isCancelAllEventsModal: false,
      isMoveAllEventsModal: false,
      isChapterCollapsed: true,
      isProcessLoading: false,
      isDeleteVehicleFilter: false,
      isAddVehicleFilter: false,
      processSubtitle: '',
      selectedEvent: null,
      defaultView: "day",
      currentDate: new Date(),
      students: [],
      vehicleOptions: [],
      selectedVehicle: null,
      licenseTypesResources: [],
      resources: [],
      chosenStudent: null,
      studentOptions: [],
      totalFilterEvents: [],
      vehicleFilters: [],
      // isStudentInfoShowOpen: !isTablet,
      isStudentInfoShowOpen: false,
      views: {
        day: true,
        month: false,
        week: false,
        agenda: false,
      },
    };
  }

  CustomToolbar = (props) => {
    if (this.state.dateText != props.label) {
      this.setState({ dateText: props.label })
    }
    return (
      <div className='rbc-toolbar'>
        <span className="rbc-btn-group"></span>
        <span className="rbc-toolbar-label">{props.label}</span>
      </div>
    );
  }

  loadStudentProfile = async ({ studentId, textBookId, eventType }) => {
    // return this.props.getStudentData(studentId, textBookId);
    if (this.studentProfile === null || !(this.studentProfile.studentId == studentId && this.studentProfile.textBookId == textBookId && eventType == this.studentProfile.eventType)) {
      this.studentProfile = await this.props.getStudentData(studentId, textBookId, false, false, eventType)
    }
    return this.studentProfile
  }

  componentWillMount = () => {
    const localStorageDate = window.localStorage.getItem("date");
    if (localStorageDate) {
      this.setState({ currentDate: new Date(localStorageDate) });
    }
  };

  resetBoard = async () =>{
    window.location.reload();
  }

  loadEvents = async () => {
    const from = moment(this.state.currentDate).hours(1).minutes(0).format('YYYY-MM-DDTHH:MM');
    const to = moment(this.state.currentDate).add(1, 'd').hours(1).minutes(0).format('YYYY-MM-DDTHH:MM');
    // const to = moment(this.state.currentDate);
    this.setState({ totalFilterEvents: [] })
    if (this.props.user.isOwner == 1 || this.props.user.userType != 1) {
      await this.props.getAllSchoolEvents(from, to);
    } else {
      let id = this.props.user.id;
      await this.props.getEvents(id, from, to);
    }
  }

  componentDidMount = async () => {
    try {      
      this.formats = {
        dayHeaderFormat: (date, culture, localizer) => {
          return localizer.format(date, 'dddd', culture);
        },
      };
      const lsV = window.localStorage.getItem('selectedVehicle');
      if (lsV !== null && lsV !== undefined && lsV !== '') {
        console.log('set from ls - car');
        this.setState({
          selectedVehicle: JSON.parse(window.localStorage.getItem('selectedVehicle')),
          isCarInInput: true
        });
      }
    } catch (err) {}

    if (this.props.isLoggedIn) {
      if (!this.props.filterIsLoaded) {
        await this.props.getFilters();
      }
      if (!this.props.isHolidaysLoaded) {
        await this.props.loadHolidays();
      }
      if (!this.props.isUsersLoaded) {
        await this.props.getUsers();
      }
      if (!this.props.isVehiclesLoaded) {
        await this.props.getVehicles();
      }
      if (!this.props.licenseTypes || this.props.licenseTypes.length == 0) {
        await this.props.getLicenseTypes();
      }
      if (!this.props.isStudentsLoaded) {
        await this.props.getStudents();
        this.setState({
          studentOptions: this.props.students.map((x) => ({
            value: x.studentId,
            label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
          })),
        });
      }
      
      const licenseTypesResources = this.props.licenseTypes.map((l) => ({ id: l.id, title: l.name }))
      const vehicleOptions = this.props.vehicles
        .filter((x) => x.active === 1)
        .map((x) => ({ value: x.id, label: x.description }));
      // const teacherOptions = [
      //   { value: -999, label: "הכל" },
      //   ...this.props.users
      //     .filter(
      //       (x) => (x.userType === 1 || x.userType === 4) && x.isActive === 1
      //     )
      //     .map((x) => ({
      //       value: x.id,
      //       label: `${x.firstName} ${x.lastName}`,
      //     })),
      // ];
      this.setState({
        // teacherOptions,
        vehicleOptions,
        // resources: licenseTypesResources,
        licenseTypesResources,
      });
      if (!this.props.isStudentsLoaded) {
        await this.props.getStudents();
        this.setState({
          studentOptions: this.props.students.map((x) => ({
            value: x.studentId,
            label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
          })),
        });
      }
    }
    await this.loadEvents();
  };

  closeModal = (type) => {
    this.setState({ [type]: false, selectedEvent: null },  () => {
      if (type == "isUpdateLessonModalOpen") this.loadEvents();
    });
  }
  openModal = (type, selectedEvent = null) => {
    return this.setState({ [type]: true, selectedEvent });
  }

  timeView = ({event, day, label}) => {
    if (event.eventType == undefined || this.state.defaultView !== 'agenda' || !label) return label;

    return label.split(' – ', 2)[0]
  }

  onFinish = () => {
    setTimeout(() => {
      this.loadEvents()
    }, 300);
  }

  onNavigate = (date) => {
    this.agentaColors = [];
    this.setState({ currentDate: date }, () => this.loadEvents());
    window.localStorage.setItem("date", date);
  }

  onView = (view) => { 
    this.setState({ defaultView: view });
  }

  setEventColors = (eventType, eventStatus) => {
    const {
      fontLessonColor,
      lessonColor,
      doneLessonColor,
      fontDoneLessonColor,
      paidLessonColor,
      fontPaidLessonColor,
      canceledLessonColor,
      fontCanceledLessonColor,
      canceledAndPaidLessonColor,
      fontCanceledAndPaidLessonColor,
      testColor,
      fontTestColor,
      doneTestColor,
      fontDoneTestColor,
      paidTestColor,
      fontPaidTestColor,
      canceledTestColor,
      fontCanceledTestColor,
      canceledAndPaidTestColor,
      fontCanceledAndPaidTestColor,
      internalTestColor,
      fontInternalTestColor,
      doneInternalTestColor,
      fontDoneInternalTestColor,
      paidInternalTestColor,
      fontPaidInternalTestColor,
      canceledInternalTestColor,
      fontCanceledInternalTestColor,
      canceledAndPaidInternalTestColor,
      fontCanceledAndPaidInternalTestColor,
      meetingColor,
      fontMeetingColor,
      doneMeetingColor,
      fontDoneMeetingColor,
      canceledMeetingColor,
      fontCanceledMeetingColor,
    } = this.props.user;
    let color = "";
    let backgroundColor = "";
    if (eventType === 1) {
      const params = this.eventColors(
        lessonColor,
        fontLessonColor,
        doneLessonColor,
        fontDoneLessonColor,
        paidLessonColor,
        fontPaidLessonColor,
        canceledLessonColor,
        fontCanceledLessonColor,
        canceledAndPaidLessonColor,
        fontCanceledAndPaidLessonColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 2) {
      const params = this.eventColors(
        testColor,
        fontTestColor,
        doneTestColor,
        fontDoneTestColor,
        paidTestColor,
        fontPaidTestColor,
        canceledTestColor,
        fontCanceledTestColor,
        canceledAndPaidTestColor,
        fontCanceledAndPaidTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 3) {
      const params = this.eventColors(
        internalTestColor,
        fontInternalTestColor,
        doneInternalTestColor,
        fontDoneInternalTestColor,
        paidInternalTestColor,
        fontPaidInternalTestColor,
        canceledInternalTestColor,
        fontCanceledInternalTestColor,
        canceledAndPaidInternalTestColor,
        fontCanceledAndPaidInternalTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 4) {
      const params = this.eventColors(
        meetingColor,
        fontMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        eventStatus
      );
      backgroundColor = params.backgroundColor;
      color = params.color;
    }
    return { backgroundColor, color };
  };

  eventColors = (
    orderBackground,
    orderFontColor,
    doneBackground,
    doneFontColor,
    paidBackground,
    paidFontColor,
    canceledBackground,
    canceledFontColor,
    canceledAndPaidBackground,
    canceledAndPaidPaidFontColor,
    eventStatus
  ) => {
    if (eventStatus === 0) {
      return { backgroundColor: orderBackground, color: orderFontColor };
    } else if (eventStatus === 1) {
      return { backgroundColor: doneBackground, color: doneFontColor };
    } else if (eventStatus === 2) {
      return { backgroundColor: paidBackground, color: paidFontColor };
    } else if (eventStatus === 3) {
      return { backgroundColor: canceledBackground, color: canceledFontColor };
    } else if (eventStatus === 4) {
      return {
        // backgroundColor: canceledAndPaidBackground,
        color: canceledAndPaidPaidFontColor,
      };
    }
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    const b = this.setEventColors(event.eventType, event.eventStatus);
    let agStyle = {}
    if (this.state.defaultView === 'agenda') {
      agStyle = this.agendaViewStyle(event)
    }
    var style = {
      borderRadius: "0px",
      border: "0px",
      display: "block",
      borderLeft: "3px solid",
      borderTop: "1px solid",
      borderRight: "1px solid",
      ...b,
      ...agStyle,
    };
    return {
      style: style,
    };
  };

  async clickStudent(value) {
    this.agentaColors = [];
    let showProfile = false;
    if (value) {
      showProfile = this.getFilteredEvents(false).filter((v) => {
        return value.value == v.studentId && v.isShow
      }).length === 0
    } else {
      this.setState({ isStudentInfoShowOpen: false })
    }
    if (!value || !this.state.chosenStudent || this.state.chosenStudent.value != value.value) {
      this.setState({ chosenStudent: value })
      if (showProfile) this.setState({ isStudentInfoShowOpen: true })
      return;
    }
    this.setState({ chosenStudent: value })
    if (showProfile) this.setState({ isStudentInfoShowOpen: true })
    if (!value || !value.event) return
    let isClose = false;
    isClose = this.studentProfile !== null && 
      this.studentProfile.studentId == value.value && 
      value.event.textBookId == this.studentProfile.textBookId &&
      value.event.eventType == this.studentProfile.eventType;
    if (isClose && this.state.isStudentInfoShowOpen) {
      this.setState({ isStudentInfoShowOpen: false })
    } else {
      this.setState({ isStudentInfoShowOpen: !this.state.isStudentInfoShowOpen })
    }
  }

  getFilteredEvents(resetShow = true) {
    const { events, holidays } = this.props;
    const existsVehicle = this.state.vehicleOptions.map((l) => l.value);
    let filteredEvents = [...events].filter((x) => x.vehicleId && existsVehicle.some((v) => v == x.vehicleId));
    if (this.state.selectedVehicle !== null && this.state.selectedVehicle.length > 0) {
      filteredEvents = filteredEvents.filter(
        (x) =>
        this.state.selectedVehicle
            .map((x) => x.value.toString())
            .indexOf(x.vehicleId) !== -1
      );
    }
    let result = [...filteredEvents, ...holidays];
    const eventsOnly = result.filter((e) => !e.allDay && e.vehicleId).map((v) => Object.assign({}, v));
    if (this.state.totalFilterEvents.length != eventsOnly.length) {
      this.setState({ totalFilterEvents: eventsOnly })
    }
    
    if (resetShow) {
      return result.map((elem, i) => {
        elem.isShow = false;
        if (!elem.allDay) {
          const vehicle = this.state.vehicleOptions.find((v) => v.value == elem.vehicleId);
          if (vehicle) elem.resourceId = vehicle.value;
        }
        if (!elem.resourceId) elem.resourceId = this.state.vehicleOptions.map((l) => l.value);
        return elem;
      })
    }
    return result;
  }

  moveEvent ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false,
    }) {
      if (event.isAllDay) return;
      const _editEvent = Object.assign({}, event);
      _editEvent.vehicleId = resourceId;
      _editEvent.start = start;
      _editEvent.end = end;
      this.setState({ selectedEvent: _editEvent}, () => {
        this.setState({ isUpdateLessonModalOpen: true })
      })
    }

  CalendarView() {
    let height = !isMobile && "82.9vh"
    if (isTablet) height = '71.5vh'
    const {
      defaultView,
      currentDate,
      chosenStudent,
      views,
    } = this.state;
    const { user } = this.props;
    const filteredEvents = this.getFilteredEvents();    
    const activeLicenseTypes = uniq(filteredEvents.map((v) => v.resourceId));
    const resources = this.state.vehicleOptions.filter((lt) => (this.state.selectedVehicle && this.state.selectedVehicle.length > 0) ? this.state.selectedVehicle.includes(lt) : activeLicenseTypes.includes(lt.value));
    // const resources = this.state.vehicleOptions.filter((lt) => activeLicenseTypes.includes(lt.value));

    return <DnDCalendar
      resourceIdAccessor = "value"
      resourceAccessor = "resourceId"
      resourceTitleAccessor = "label"
      resources = {resources}
      formats={this.formats}
      popup
      ref={(c) => { this.bigCalendar = c; } }
      views={views}
      messages={messages}
      localizer={localizer}
      culture="he"
      // showAllEvents={true}
      components={{
        toolbar : this.CustomToolbar,
        time: this.timeView,
        day: {          
          event: (props) => {
            props.event.isShow = true;
            return !props.event.allDay ? (
              <EventVehicleDayView
                event={props.event}
                chosenStudentId={this.state.chosenStudent?.value}
                step={user.calendarSpacingInMinutes}
                loadStudentProfile = {this.loadStudentProfile}
                getStudentEventCount={this.props.getStudentEventCount}
                onStudentClick={(value) =>
                  this.clickStudent(value)
                }
                openEventStatusChangeModal={(event) =>
                  this.openModal("isEventStatusChangeModalOpen", event)
                }
              />
            ) : (
              <div style={{ textAlign: "center" }}>{props.event.title}</div>
            );
          }
        },
      }}
      events={filteredEvents}
      step={user.calendarSpacingInMinutes}
      timeslots={1}
      selectable
      startAccessor="start"
      endAccessor="end"
      rtl={true}
      slotEventOverlap={false}
      onNavigate={this.onNavigate}
      view={defaultView}
      onView={this.onView}
      date={currentDate}
      length={ 1 }
      min={
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date(user.calendarStartTime ? user.calendarStartTime : '2025-08-30T03:00:00.221Z').getHours()
        )
      }
      max={
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date(user.calendarEndTime? user.calendarEndTime : '2025-08-30T17:00:00.801Z').getHours()
        )
      }
      eventPropGetter={this.eventStyleGetter}
      style={{ height, flexGrow: "1" }}
      onSelectSlot={(e) =>
        typeof e.resourceId !== "undefined" &&
        (this.state.isBlockActive
          ? this.createBlockEvent(e)
          : this.openModal("isNewLessonModalOpen", e))
      }
      onEventDrop={(e) => this.moveEvent(e)}
    />
  }

  handleNavigate(type) {
    let date = new Date();
    switch (`${type}`.toUpperCase()) {
      case "NEXT":
        date = moment(this.state.currentDate).add(1, 'd').toDate()
      break;
      case "PREV":
        date = moment(this.state.currentDate).add(-1, 'd').toDate()
      break;
    }
    this.onNavigate(date);
  }

  ButtonsView () {
    const {
      currentDate,
      dateText,
    } = this.state;
    const { events, user } = this.props;    
    return (
      <Column>
        <Row className="row-select">
          <Button className="button-navigation"
            text="הבא"
            marginRight="0.3"
            onClick={ () => this.handleNavigate('NEXT') }
          />
          <Button className="button-navigation"
            text="הקודם"
            marginRight="0.3"
            onClick={ () => this.handleNavigate('PREV') }
          />
          <Button className="button-navigation"
            text="היום"
            marginRight="0.3"
            onClick={ () => this.handleNavigate('TODAY') }
          />
          <input type="text" 
            className="calendar-date-label"
            value={ dateText }
            disabled
          /> 
          <input type="date" 
            style={{ flexGrow: "1" }} 
            value={ moment(currentDate).local('he').format('YYYY-MM-DD') } 
            onChange={ (e) => { this.onNavigate(new Date(e.target.value)) }}
          />
        </Row>
        {/* <Row className="row-select">
          <Button className="button-action"
            title="Cancel all events in calendar"
            text={ <i class="fa-lg fa-solid fa-rectangle-xmark"></i> }
            alt="מסמך כספי"
            disabled={ this.state.totalFilterEvents.length == 0 }
            marginRight="0.3"
            onClick={ () => this.setState({ isCancelAllEventsModal: true }) }
          />
          <Button className="button-action"
            title="Move all events in calendar"
            text={ <i class="fa-lg fa-solid fa-share-from-square"></i> }
            alt="מסמך כספי"
            disabled={ this.state.totalFilterEvents.length == 0 }
            marginRight="0.3"
            onClick={ () => this.setState({ isMoveAllEventsModal: moment().add(1, "d") }) }
          />
        </Row> */}
      </Column>     
    );
  }

  handleVehicleChange = async (selectedVehicle) => {
    this.setState({ selectedVehicle }, () => {
      if (this.state.isCarInInput) {
        console.log('set vehicle in ls');
        window.localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
      }
    });
  };

  updateProcessSubtitle() {
    if (!this.state.isProcessLoading) return;
    let subtitle = `Идет обработка уроков: ${this.state.totalFilterEvents.length} / ${this.state.totalFilterEvents.filter((e) => e.isFinished).length} уроков.`;
    this.setState({ processSubtitle: subtitle }, () => setTimeout(() => this.updateProcessSubtitle()), 1000);
  }

  changeMoveDate(date) {
    console.log('DefaultDateTimePicker', date);
    this.setState({ isMoveAllEventsModal: date });
  }

  async moveAllEvents(reason) {
    if (reason === false) {
      return this.setState({ isMoveAllEventsModal: false });
    }
    this.setState({ isProcessLoading: true }, () => this.updateProcessSubtitle());
    for (const event of this.state.totalFilterEvents) {
      const startDate = moment(event.start);
      const endDate = moment(event.end);
      const changedData = {
        id: event.id,
        start: moment(this.state.isMoveAllEventsModal).hours(startDate.hours()).minutes(startDate.minutes()).seconds(0).milliseconds(0).toISOString(),
        end: moment(this.state.isMoveAllEventsModal).hours(endDate.hours()).minutes(endDate.minutes()).seconds(0).milliseconds(0).toISOString()
      };
      await this.props.updateEvent(changedData, true);
      event.isFinished = true;
    }
    this.setState({isProcessLoading: false, isMoveAllEventsModal: false, processSubtitle: '' });
    this.state.totalFilterEvents.map((e) => e.isFinished = false);
    this.loadEvents()
  }

  async cancelAllEvents(reason) {
    if (reason === false) {
      return this.setState({ isCancelAllEventsModal: false });
    }
    this.setState({ isProcessLoading: true }, () => this.updateProcessSubtitle());
    for (const event of this.state.totalFilterEvents) {
      await this.props.updateEventStatus(event.id, 3, reason, event.eventType);
      event.isFinished = true;
    }
    this.setState({isProcessLoading: false, isCancelAllEventsModal: false, processSubtitle: '' });
    this.state.totalFilterEvents.map((e) => e.isFinished = false);
    this.loadEvents()
  }
  

  onClickVehicleFilter({ params }) {
    if (!params) return;
    const filterParams = JSON.parse(params);
    const selectedVehicle = this.state.vehicleOptions.filter((l) => filterParams.includes(l.value))
    this.setState({ selectedVehicle });
  }
  

  onClickDeleteFilter(filter) {
    if (!filter) return;
    this.setState({ isDeleteVehicleFilter: filter })
  }

  deleteFilter(isDelete) {
    if (!isDelete) {
      return this.setState({ isDeleteVehicleFilter: false })
    };
    this.props.deleteFilter(this.state.isDeleteVehicleFilter.id);
    this.setState({ isDeleteVehicleFilter: false })
  }

  addVehicleFilter(name) {
    if (!name) {
      return this.setState({ isAddVehicleFilter: false })
    };    
    const data = {
      params: this.state.selectedVehicle.map((v) => v.value),
      name,
      type: "vehicle"
    }
    this.props.addFilter(data);
    this.setState({ isAddVehicleFilter: false })
  }

  isSelectedVehicleFilter(filter) {
    const filterParams = JSON.parse(filter.params);
    if (!this.state.selectedVehicle || filterParams.length != this.state.selectedVehicle.length) return false;
    const selectedInFilter = this.state.selectedVehicle.filter((v) => filterParams.includes(v.value));
    return selectedInFilter.length == filterParams.length;
  }

  SelectorsView () {
    const {
      selectedVehicle,
      vehicleOptions,
      isCarInInput,
      chosenStudent
    } = this.state;
    const { user, students } = this.props;

    const copyStydent = () => {
      navigator.clipboard.writeText(this.state.chosenStudent.label);
    }
    const copyVehicle = () => {
      navigator.clipboard.writeText(this.state.selectedVehicle.map((v) => v.label).join(', '));
    }
    return (
      <Column>        
        <Row className="row-select">
          { students.length > 0 && (
            <>
              <Toggle className="select-toggle"
                id="cheese-status-student"
                defaultChecked={false}
              />
              <Select
                isClearable
                options={ students
                  .filter((x) => x.active === 1)
                  .map((x) => ({
                    value: x.studentId,
                    label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
                  }))}
                value={chosenStudent}
                placeholder="בחר תלמיד"
                styles={customStyles}
                isRtl={true}
                onChange={(value) => this.clickStudent(value) }
                style={{
                  minWidth: '100%'
                }}
              />
              {this.state.chosenStudent && <Icon onClick={copyStydent} />}
            </> 
          )}
        </Row>
        { user.userType === 1 ? null : (
          <>
            <Row className="row-select">
              <Toggle className="select-toggle"
                id="cheese-status-vehicle"
                defaultChecked={false}
                checked={isCarInInput}
                value={isCarInInput}
                onChange={() =>
                  this.setState({ isCarInInput: isCarInInput ? false : true }, () => {
                    if (!isCarInInput) window.localStorage.removeItem('selectedVehicle');
                    else {
                      if (isCarInInput) {
                        console.log('set vehicle in ls');
                        window.localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
                      }
                    }
                  })
                }
              />
              <Select
                options={vehicleOptions}
                isMulti
                value={selectedVehicle}
                placeholder="רכב"
                styles={customStyles}
                isRtl={true}
                onChange={this.handleVehicleChange}
                style={{
                  minWidth: '100%'
                }}
              />
              {this.state.selectedVehicle && this.state.selectedVehicle.length > 0 && <Icon type="plus" onClick={() =>this.setState({ isAddVehicleFilter: true })} />}
              {this.state.selectedVehicle && this.state.selectedVehicle.length > 0 && <Icon onClick={copyVehicle} />}
            </Row>
            <Row className="row-select">
              <TagList options={this.props.filters} onClose={(v) => this.onClickDeleteFilter(v)} onClick={(v) => this.onClickVehicleFilter(v)} isActive={(value) => this.isSelectedVehicleFilter(value)} />
            </Row>
          </>  
        )}  
      </Column>
    );
  }

  ActionsView () {
    const {
      isNewLessonModalOpen,
      isNotesModalOpen,
      isErrorModalOpen,
      isUpdateLessonModalOpen,
      isTeacherInInput,
      isCarInInput,
      isEventStatusChangeModalOpen,
      chosenStudent,
      selectedTeacher,
      selectedVehicle,
      selectedEvent,
      errorModalText,
      isCancelAllEventsModal,
      isMoveAllEventsModal,
      isProcessLoading,
      isDeleteVehicleFilter,
      isAddVehicleFilter,
    } = this.state;
    const { user } = this.props;    
    return (
      <>
        {isNewLessonModalOpen && (
          <NewLesson
            isNewAgreementModalOpen={isNewLessonModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNewLessonModalOpen")
            }
            onFinish={() => this.onFinish()}
            selectedEvent={selectedEvent}
            step={user.eventTime}
            studentFromCalendar={chosenStudent?.value}
            selectedTeacher={isTeacherInInput ? selectedTeacher : []}
            selectedVehicle={[{ value: this.state.selectedEvent.resourceId }]}
          />
        )}
        {isUpdateLessonModalOpen && (
          <EditEvent
            isNewAgreementModalOpen={isUpdateLessonModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isUpdateLessonModalOpen")
            }
            onFinish={() => this.onFinish()}
            selectedEvent={selectedEvent}
            step={user.eventTime}
          />
        )}
        {isNotesModalOpen && (
          <NoteChange
            isNewAgreementModalOpen={isNotesModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNotesModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isErrorModalOpen && (
          <ErrorModal
            modalIsOpen={isErrorModalOpen}
            closeModal={() => this.closeModal("isErrorModalOpen")}
            text={errorModalText}
          />
        )}
        {isEventStatusChangeModalOpen && (
          <EventStatusChange
            isNewAgreementModalOpen={isEventStatusChangeModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isEventStatusChangeModalOpen")
            }
            selectedEvent={selectedEvent}
            step={user.calendarSpacingInMinutes}
          />
        )}
        {isCancelAllEventsModal && (
          <Promt
            isLoading={isProcessLoading}
            isModalOpen={isCancelAllEventsModal}
            warnign={this.state.processSubtitle}
            defaultValue=""
            placeholder="Cancel reason"
            btn2DefaultValue={false}
            onClose={(result) => 
              this.cancelAllEvents(result)
            }
            title={`Do you want to cancel ${this.state.totalFilterEvents.length} events ?`}
            txtBtn1="Delete"
            txtBtn2="Cancel"
          />
        )}
        {isMoveAllEventsModal && (
          <Promt
            isLoading={isProcessLoading}
            isModalOpen={true}
            defaultValue={true}
            btn2DefaultValue={false}
            warnign={this.state.processSubtitle}
            onClose={(result) => {
              this.moveAllEvents(result)
              }
            }
            title={`Do you want to move ${this.state.totalFilterEvents.length} events ?`}
            txtBtn1="Move"
            txtBtn2="Cancel"
          >
            <DefaultDatePicker input={{ value: isMoveAllEventsModal, onChange: (x) => this.changeMoveDate(x) }} meta={{}} />
          </Promt>
        )}
        { isDeleteVehicleFilter && (<Promt
            isLoading={false}
            isModalOpen={true}
            defaultValue={true}
            btn2DefaultValue={false}
            onClose={(result) => {
              this.deleteFilter(result)
              }
            }
            title={`?"${this.state.isDeleteVehicleFilter.name}" האם ברצונך למחוק מסנן `}
            txtBtn1="כֵּן"
            txtBtn2="לֹא"
          ><span /></Promt>)}
        { isAddVehicleFilter && (<Promt
            isLoading={false}
            isModalOpen={true}
            defaultValue=""
            placeholder="נא לכתוב את שם המסנן"
            btn2DefaultValue={false}
            onClose={(result) => {
              this.addVehicleFilter(result)
              }
            }
            title={`האם אתה רוצה להוסיף מסנן?`}
            txtBtn1="כֵּן"
            txtBtn2="לֹא"
          />)}
      </>
    )}
  render() {
    const {
      isFoldMenu,
      currentDate,
      chosenStudent,
      isStudentInfoShowOpen,
      dateText,
      isChapterCollapsed
    } = this.state;
    const { user } = this.props;
    // const filteredEvents = this.getFilteredEvents();
    return (
      <>
      <BrowserView style={{display: "flex", width: "100%"}}>
        <Container style={{width: "100%"}}>
        <ContainerVehicle style={{width: "100%"}}>
          {/**/}
          <HeaderContainer isRtl={true}>
            <LogoWithUserContainer>
              <Logo src={SivanPlusLogo} alt="Logo" />                  
              <Row>
                <Column alignItems="flex-end">
                  <span style={{ fontSize: "18pt" }}>-{user.firstName} {user.lastName}</span>
                  <span style={{ fontSize: "18pt" }}>{user.schoolName}</span>
                </Column>
                <span style={{ paddingLeft: "10px" }}>
                  <IconSwitch
                    className={isFoldMenu ?"fa-3x fas fa-fw fa-list-alt" :"fa-3x fas fa-fw fa-window-close"}
                    isFoldMenu={isFoldMenu}
                    onClick={() => this.setState({ isFoldMenu: !isFoldMenu})}
                  ></IconSwitch>
                </span>
              </Row>
            </LogoWithUserContainer>
            <SelectContainer>
              { this.SelectorsView() }
            </SelectContainer>
            <ButtonContainer>
              { this.ButtonsView() }
            </ButtonContainer>
          </HeaderContainer>
          {/**/}
          <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
            { this.CalendarView() }
            {chosenStudent !== null && isStudentInfoShowOpen && (
              <StudentBasicInfo
                forEvent={chosenStudent.event}
                studentId={chosenStudent.value}
                loadStudentProfile={this.loadStudentProfile}
                close={() => this.closeModal("isStudentInfoShowOpen")}
                handleTeacherChange={this.handleTeacherChange}
                dateChange={(date) => this.setState({ currentDate: date })}
              />
            )}
            <div style={{ paddingRight: "10px" }}>
              <Menu isCalendarpPage={true} isFoldMenu={isFoldMenu} />
            </div>
          </div>
        </ContainerVehicle>
        { this.ActionsView() }
        </Container>
      </BrowserView>

      <MobileOnlyView>
        <Container>
        <ContainerVehicle>
          <StickyHeader isRtl={true} style={{ position: "relative" }}>
            <UserOptionsContainer>
              <Row style={{ paddingBottom: "2px" }}>
                <Logo src={SivanPlusLogo} alt="Logo" style={{ paddingRight: "15px" }}/>

                <span style={{ paddingLeft: "10px", paddingRight: "2px" }}>
                  <IconSwitch
                    className={isFoldMenu ?"fa-3x fas fa-fw fa-list-alt" :"fa-3x fas fa-fw fa-window-close"}
                    isFoldMenu={isFoldMenu}
                    onClick={() => this.setState({ isFoldMenu: !isFoldMenu})}
                  ></IconSwitch>
                </span>
              </Row>
              <ChapterTitle dir="auto">
                לְסַנֵן
                <i
                  className={`fa fa-${isChapterCollapsed ? "minus" : "plus"}-square-o`}
                  aria-hidden="true"
                  onClick={() => this.setState({ isChapterCollapsed: !isChapterCollapsed })}
                />
              </ChapterTitle>
              <Collapse
                isOpened={isChapterCollapsed}
                style={{ width: "40%", display: "flex" }}
              >
              <Row className="row-select">
                <input type="text" 
                  className="calendar-date-label"
                  value={ dateText }
                  disabled
                />
                <input type="date" 
                  style={{ flexGrow: "1" }} 
                  value={ moment(currentDate).local('he').format('YYYY-MM-DD') } 
                  onChange={ (e) => { this.onNavigate(new Date(e.target.value)) }}
                />
              </Row>
              <Row className="row-select" justifyContent="space-between">
                <SplitButton
                  className="split-button-navigation"
                  options={[ "הבא", "הקודם", "היום" ]}
                  onClick={[
                    () => this.handleNavigate('NEXT'),
                    () => this.handleNavigate('PREV'),
                    () => this.handleNavigate('TODAY')
                  ]}
                />                
              </Row>
                {this.SelectorsView()}
              </Collapse>
            </UserOptionsContainer>
          </StickyHeader>
          <StickyBodyMobile style={{ paddingTop: 0 }}>
            
            <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
              { this.CalendarView() }
              <Burger>  
                <div class="burger-menu-wrap">
                  <div class="burger-menu">
                    <Menu isCalendarpPage={true} isFoldMenu={isFoldMenu} />
                  </div>      
                </div>    
              </Burger>

              {isMobileOnly && chosenStudent !== null && isStudentInfoShowOpen && (

                <Burger zIndex={"1000"}>  
                <div class="burger-menu-wrap" style={{ top: "95px" }}>
                  <div class="burger-menu">
                    <StudentBasicInfo
                      forEvent={chosenStudent.event}
                      studentId={chosenStudent.value}
                      loadStudentProfile={this.loadStudentProfile}
                      close={() => this.closeModal("isStudentInfoShowOpen")}
                      handleTeacherChange={this.handleTeacherChange}
                      dateChange={(date) => this.setState({ currentDate: date })}
                    />
                  </div>      
                </div>    
              </Burger>
              )}


            </div>
          </StickyBodyMobile>
          </ContainerVehicle>
          { this.ActionsView() }
        </Container>
      </MobileOnlyView>

      <TabletView style={{display: "flex", width: "100%"}}>
        <Container style={{width: "100%"}}>
        <ContainerVehicle style={{width: "100%"}}>
          <HeaderContainer isRtl={true}>
            <Column>
              <Row>
                <Column >
                    { this.SelectorsView() }
                </Column>
                <Column alignItems="flex-end" style={{ width: "50vw" }}>
                  <LogoWithUserContainer>
                    <Logo src={SivanPlusLogo} alt="Logo" />        
                    &nbsp;&nbsp;
                    <Row>
                      <Column alignItems="flex-end">
                        <span style={{ fontSize: "15pt" }}>-{user.firstName} {user.lastName}</span>
                        <span style={{ fontSize: "15pt" }}>{user.schoolName}</span>
                      </Column>
                      <span style={{ paddingLeft: "10px" }}>
                        <IconSwitch
                          className={isFoldMenu ?"fa-3x fas fa-fw fa-list-alt" :"fa-3x fas fa-fw fa-window-close"}
                          isFoldMenu={isFoldMenu}
                          onClick={() => this.setState({ isFoldMenu: !isFoldMenu})}
                        ></IconSwitch>
                      </span>
                    </Row>
                  </LogoWithUserContainer>
                </Column>
              </Row>
              <ButtonContainer>
                { this.ButtonsView() }
              </ButtonContainer>
            </Column>
          </HeaderContainer>

          <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
            { this.CalendarView() }

            <Burger>
              <div class="burger-menu-wrap">
                <div class="burger-menu">
                  <Menu isCalendarpPage={true} isFoldMenu={isFoldMenu} />
                </div>      
              </div>    
            </Burger>
          </div>
        </ContainerVehicle>
        { this.ActionsView() }
        </Container>
      </TabletView>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    showDailyBikes: state.session.user.showDailyBikes,
    events: state.CalendarReducer.events,
    holidays: state.CalendarReducer.holidays,
    isHolidaysLoaded: state.CalendarReducer.isHolidaysLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    filterIsLoaded: state.UsersReducer.filterIsLoaded,
    filters: state.UsersReducer.filters,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    user: state.session.user,
    students: state.StudentsReducer.students,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getFilters,
  deleteFilter,
  addFilter,
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  getStudents,
  createEvent,
  getVehicles,
  getStudentData,
  getLicenseTypes,
  updateEventStatus,
  updateEvent,
})(Calendara);
